@tailwind base;
@tailwind components;
@tailwind utilities;
.jumbotron {
  background-image: url(../../images/newyork1.JPG);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: white;
}
.newyork {
  background-image: url(../../images/denmark1.JPG);
}

.rotateimg180 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  padding: 35px;
}
